import { render, staticRenderFns } from "./TripService.vue?vue&type=template&id=4ff4fb01&scoped=true&"
import script from "./TripService.vue?vue&type=script&lang=js&"
export * from "./TripService.vue?vue&type=script&lang=js&"
import style0 from "./TripService.vue?vue&type=style&index=0&id=4ff4fb01&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ff4fb01",
  null
  
)

export default component.exports