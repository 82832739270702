<template>
  <iframe :src="url" title="Book your trip" @load="$store.commit('loader/hide')" />
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TripService',
  props: {
    serviceId: { type: String, required: true },
  },
  data() {
    return {
      url: null,
    }
  },
  computed: {
    ...mapGetters({ services: 'project/services' }),
  },
  async created() {
    this.$store.commit('loader/show')
    this.url = this.services.find(service => service.id === this.serviceId)?.url
  },
}
</script>

<style lang="scss" scoped>
iframe {
  width: 100%;
  height: 100%;
  border: none;
}
</style>
